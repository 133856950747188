import React from 'react'
import './GradientBox.css'

const GradientBox = (props) => {

  return (
    <div className={`gradient-box ${props.className}`} style={props.outerBoxStyle}>
        {props.children}
    </div>
  )
}

export default GradientBox
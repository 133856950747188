import React from 'react';
import '../PokerRules.css';

const StartingHandsOmaha = ({ title, rank, cards }) => {
    return (
        <>
            <div className="starting-hand-omaha">
                <h3 className="poker-hand-rank">#{rank}</h3>
                <div className="omaha-card-container">
                    <img className="omaha-cards" src={cards} />
                    <p className="poker-hand-title">{title}</p>
                </div>
            </div>
        </>
    );
};

export default StartingHandsOmaha;

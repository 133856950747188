import React from 'react';
import FourColumnLayout from '../../components/Layouts/FourColumnLayout';
import PageBackground from '../../components/Layouts/PageBackground';
import { certifiedfair } from '../../data';
import Pdf from "../../assets/cfg.pdf"

const CertifiedFair = () => {
  return (
    <PageBackground
      title="CERTIFICATIONS"
    >
      <FourColumnLayout data={certifiedfair} certifClick={()=>window.open(Pdf)} />
    </PageBackground>
  );
};

export default CertifiedFair;

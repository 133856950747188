import React from 'react'
import './Title.css'
import sopoDiamondLogo from '../assets/icons/sopo-diamond-logo.png';

const Title = ({title}) => {

  return (
    <div className="title">
      <div className="line"></div>
      <div className="diamond-logo">
        <img src={sopoDiamondLogo} alt="sopo-diamond-logo"/>      
      </div>
      <div className="text">{title}</div>
    </div>
  )
}

export default Title
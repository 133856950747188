import React from 'react';
import GradientBox from '../../components/GradientBox';
import PokerHand from './components/PokerHand';
import './PokerRules.css';
import cardType from '../../assets/images/pokerrules/card-types.svg';
import line from '../../assets/images/howtoplay/line.svg';
import { pokerhand, startinghandstexas, startinghandsomaha } from '../../data';
import StartingHandsTexas from './components/StartingHandsTexas';
import StartingHandsOmaha from './components/StartingHandsOmaha';

const PokerRules = () => {
    return (
        <>
            <div className="box-container">
                <GradientBox className={'poker-rules-box'}>
                    <h3 className="poker-rules-title">
                        Poker Suits Explained
                    </h3>
                    <p className="poker-rules-text">
                        A deck of cards has four suits: Hearts,
                        Diamonds, Clubs, and Spades. The suits are
                        represented by symbols on the cards, such as a
                        heart for Hearts and a spade for Spades. The
                        suits are sometimes abbreviated as "H" for
                        Hearts, "D" for Diamonds, "C" for Clubs, and "S"
                        for Spades.
                    </p>
                    <img className="card-type" src={cardType} alt="card-type"/>
                    <p className="poker-rules-text">
                        In poker, the suits of the cards are not ranked
                        and are only important for making certain hands,
                        such as a flush, straight flush, or royal flush.
                    </p>
                </GradientBox>
                <GradientBox className={'poker-rules-box'}>
                    <h3 className="poker-rules-title">
                        Poker Hand Rankings
                    </h3>
                    <p className="poker-hand-description">
                        In most variations of poker, including Texas
                        Hold'em, Stud, and PLO, the cards are ranked
                        from lowest to highest as follows: 2, 3, 4, 5,
                        6, 7, 8, 9, 10, Jack, Queen, King, and Ace.
                    </p>
                    <p className="poker-hand-description">
                        The following is a list of the standard poker
                        hand rankings, from lowest to highest:
                    </p>
                    <img className="poker-rule-line" src={line} alt="line"/>
                    {pokerhand.map((hand) => (
                        <PokerHand
                            title={hand.title}
                            description={hand.description}
                            probality={hand.probality}
                            combinations={hand.combinations}
                            cards={hand.image}
                        />
                    ))}
                </GradientBox>
                <GradientBox className={'poker-rules-box'}>
                    <h3 className="poker-rules-title">
                        TOP 10 STARTING HANDS TEXAS HOLD‘EM
                    </h3>
                    <div className="starting-hand-texas">
                        {startinghandstexas.map((hand) => (
                            <StartingHandsTexas
                                title={hand.title}
                                description={hand.description}
                                cards={hand.image}
                            />
                        ))}
                    </div>
                </GradientBox>
                <GradientBox className={'poker-rules-box'}>
                    <h3 className="poker-rules-title">
                        TOP 10 STARTING HANDS OMAHA
                    </h3>
                    <div className="starting-hands-omaha">
                        {startinghandsomaha.map((hand) => (
                            <StartingHandsOmaha
                                title={hand.title}
                                rank={hand.rank}
                                cards={hand.image}
                            />
                        ))}
                    </div>
                </GradientBox>
                <GradientBox className={'poker-rules-box'}>
                    <h3 className="poker-rules-title">POKER ODDS</h3>
                    <div>
                        <h3 className="poker-hand-title">
                            WHAT ARE POT ODDS?
                        </h3>
                        <p className="poker-hand-description">
                            Pot odds are the ratio between the size of
                            the pot and the size of the bet. For
                            example, if there is $2 in the pot and the
                            player before you bets $1, your pot odds are
                            3 to 1, because you have to bet 1/3 of the
                            pot to have a chance to win it all.
                        </p>
                    </div>
                    <p className="poker-hand-description">
                        To calculate pot odds, divide the amount of the
                        bet by the total size of the pot. For example,
                        if there is $200 in the pot and the player
                        before you bets $100, the pot will reach $400 if
                        you call. To calculate your pot odds, divide
                        $100 by $400, which equals 0.25 or 25 percent.
                        This means that in order to profit from this
                        situation, you need to win more than 25% of the
                        time.
                    </p>
                    <div>
                        <h3 className="poker-hand-title">
                            WHAT ARE IMPLIED ODDS?
                        </h3>
                        <p className="poker-hand-description">
                            Poker hand implied odds are an estimation of
                            how much you can win from a bet. For
                            example, if your opponent bets $20 into a
                            $80 pot, your pot odds give you 5 to 1,
                            meaning you're risking $20 to potentially
                            win $100. Implied odds are a way to estimate
                            the potential rewards of a bet, in addition
                            to the pot odds.
                        </p>
                    </div>
                    <div>
                        <h3 className="poker-hand-title">
                            WHAT IS PROBABILITY?
                        </h3>
                        <p className="poker-hand-description">
                            Probability deals with the likelihood of one
                            outcome over another. For example, when
                            flipping a coin, there is a 50 percent
                            chance it will land on heads and a 50
                            percent chance it will land on tails.
                            Probability is a useful tool for calculating
                            the chances of certain cards being dealt in
                            a game of poker.
                        </p>
                    </div>
                    <p className="poker-hand-description">
                        A standard deck of cards has 52 cards, four
                        suits, and 13 ranks (2 to ace). The odds of
                        being dealt an ace first are 1 in 13, while the
                        odds of being dealt a heart are 1 in 4. Every
                        card dealt changes the probability of future
                        cards. For example, if you are dealt a king
                        first, the odds of receiving another king are 3
                        in 51, or about 5.9 percent.
                    </p>
                </GradientBox>
                <GradientBox className={'poker-rules-box'}>
                    <h3 className="poker-rules-title">
                        TOP POKER GAMES & THEIR RULES
                    </h3>
                    <p className="poker-hand-description">
                        The rankings of poker hands can vary depending
                        on the game you are playing. However, in the
                        three most popular poker games (Texas Hold'em,
                        Omaha, and Seven-Card Stud), the hand rankings
                        remain the same. These games are the most widely
                        played, and the hand rankings are considered
                        standard for poker.
                    </p>
                    <img className="poker-rule-line" src={line} alt="line"/>
                    <div>
                        <h3 className="poker-hand-title">
                            TEXAS HOLD‘EM RULES
                        </h3>
                        <p className="poker-hand-description">
                            Texas Hold'em is a popular poker variant
                            that is played by 2-8 people. The aim of the
                            game is to create the best five-card poker
                            hand using the two hole cards dealt to each
                            player and the five community cards on the
                            board. The game has four main phases:
                        </p>
                    </div>
                    <p className="poker-hand-description">
                        Pre flop: The small blind is the player to the
                        left of the dealer, and the big blind is to the
                        left of the small blind. Each player is dealt
                        two cards, followed by a round of betting
                        starting with the player to the left of the big
                        blind and going clockwise around the table.
                    </p>
                    <p className="poker-hand-description">
                        Flop: After everyone has acted, the dealer burns
                        one card, places it face down on the board, and
                        reveals three face-up community cards.
                    </p>
                    <p className="poker-hand-description">
                        Turn: Another round of betting occurs, and then
                        the dealer burns and reveals one more community
                        card.
                    </p>
                    <p className="poker-hand-description">
                        River: At this stage, there are four community
                        cards on the table. The dealer burns one more
                        card and reveals the final community card,
                        making a total of five community cards. After
                        this stage, no more cards are dealt, and there
                        is only one more round of betting before the
                        showdown. In the showdown, all players still in
                        the hand reveal their cards in the order in
                        which they acted.
                    </p>
                    <p className="poker-hand-description">
                        If a player is eliminated at any stage, the next
                        player to the left of the small blind goes
                        first.
                    </p>
                    <div>
                        <h3 className="poker-hand-title">
                            OMAHA POKER RULES
                        </h3>
                        <p className="poker-hand-description">
                            Omaha is a poker game played by 2-8 players.
                            The rules of the game are similar to Texas
                            Hold'em, with a few key differences. Here is
                            an overview of the game:
                        </p>
                    </div>
                    <p className="poker-hand-description">
                        Pre-Flop: The game starts with each player
                        receiving one face-up card, and the player with
                        the highest card becomes the dealer. The blinds
                        are posted, and then each player is dealt four
                        hole cards. A first round of betting occurs,
                        where players can raise, call, or fold.
                    </p>
                    <p className="poker-hand-description">
                        In Omaha, players must use exactly two of their
                        hole cards and three of the community cards to
                        create the best five-card poker hand. For
                        example, if you have four aces in your hand, you
                        cannot use all of them to create a four of a
                        kind, you must use only two of them.
                    </p>
                    <p className="poker-hand-description">
                        Flop: After the preflop betting is resolved, one
                        card is burned and three community cards are
                        revealed. Another round of betting follows, with
                        the same three options (raise, call, or fold).
                    </p>
                    <p className="poker-hand-description">
                        Turn: One more card is burned, and another
                        community card is revealed. Another round of
                        betting follows, with the same three options.
                    </p>
                    <p className="poker-hand-description">
                        River: The river is the final phase of the game.
                        One more card is burned, and the last community
                        card is revealed. This round of betting is
                        identical to the previous ones. If more than two
                        players are still in the hand, they go to the
                        showdown and reveal their best five-card poker
                        hand using two of their hole cards and three of
                        the community cards.
                    </p>
                    <div>
                        <h3 className="poker-hand-title">
                            STUD POKER RULES
                        </h3>
                        <p className="poker-hand-description">
                            Stud poker is a variation of the game that
                            is played with varying numbers of cards and
                            betting rounds. The rules of the game can
                            differ depending on the specific version,
                            but here is an overview of two common
                            variations: Five Card Stud and Seven Card
                            Stud.
                        </p>
                    </div>
                    <p className="poker-hand-description">
                        Five Card Stud: To start the game, each player
                        places an ante in the pot and the player with
                        the lowest ranked card must pay a compulsory bet
                        called the "Bring in". Then, each player is
                        dealt one card face down and another face up.
                        This is followed by the first betting round.
                    </p>
                    <p className="poker-hand-description">
                        Next, each player is dealt a third face-up card
                        and another betting round follows. Surviving
                        players are dealt a fourth card, and another
                        round of betting follows. Everyone who hasn't
                        folded yet receives a final card, and the last
                        round of betting occurs. In the showdown, all
                        players reveal their five-card poker hand, and
                        the highest-ranked hand wins the pot.
                    </p>
                    <p className="poker-hand-description">
                        Seven Card Stud: Players receive a total of
                        seven cards throughout the game and can pick the
                        best five to make up their hand. At the start of
                        the game, each player posts the ante and
                        receives three cards, two face down and one face
                        up. The player with the lowest card showing must
                        pay the "Bring in" wager, and play continues
                        clockwise to the next player on the left.
                    </p>
                </GradientBox>
                <GradientBox className={'poker-rules-box'}>
                    <h3 className="poker-rules-title">POKER FAQ</h3>
                    <div>
                        <h3 className="poker-hand-title">
                            CAN A STRAIGHT BE JACK, QUEEN, KING, ACE, 2?
                        </h3>
                        <p className="poker-hand-description">
                            No. In poker, a straight is a hand that
                            consists of five cards in sequential rank.
                            For example, a straight could be 4, 5, 6, 7,
                            8. A straight cannot be in a non-sequential
                            order, such as Jack, Queen, King, Ace, 2.
                        </p>
                    </div>
                    <div>
                        <h3 className="poker-hand-title">
                            WHAT HAPPENS WHEN THERE IS A DRAW?
                        </h3>
                        <p className="poker-hand-description">
                            If two players have the same hand in poker,
                            the next highest card is used to determine
                            the winner. If the hands are still equal and
                            a decision cannot be reached, the pot is
                            split evenly between the two players. In the
                            case of an uneven number of chips in the
                            pot, the player closest to the left of the
                            dealer receives the remainder.
                        </p>
                    </div>
                    <div>
                        <h3 className="poker-hand-title">Example:</h3>
                        <p className="poker-hand-description">Kicker</p>
                        <p className="poker-hand-description">
                            Player One—Ace, Ace, 8, 5, 7
                        </p>
                    </div>
                    <p className="poker-hand-description">
                        Player Two—Ace, Ace, 2, 4, 6
                    </p>
                    <p className="poker-hand-description">
                        Hand One is the winner, both have a pair of
                        aces, but Hand One has an 8 kicker which
                        outranks the rest of Player Two’s cards.
                    </p>
                    <div>
                        <h3 className="poker-hand-title">Split Pot</h3>
                        <p className="poker-hand-description">
                            Player One—King, King, 7, 6, 8
                        </p>
                    </div>
                    <p className="poker-hand-description">
                        Player Two—King, King, 7, 6, 8
                    </p>
                    <p className="poker-hand-description">
                        It’s a split pot because both hands are of equal
                        rank
                    </p>
                    <div>
                        <h3 className="poker-hand-title">
                            DO YOU HAVE TO SHOW YOUR CARDS AT THE END OF
                            A SHOWDOWN?
                        </h3>
                        <p className="poker-hand-description">
                            In poker, players can choose to "muck" their
                            cards in certain situations. If a player is
                            second to act and their opponent reveals a
                            stronger hand, the player can discard their
                            hand without showing their cards to the
                            other players. Mucking can be useful for
                            players who want to conceal their play style
                            from others. In online poker, the option to
                            muck may be indicated by a flashing button.
                        </p>
                    </div>
                    <div>
                        <h3 className="poker-hand-title">
                            WHAT’S THE BEST HAND IN POKER?
                        </h3>
                        <p className="poker-hand-description">
                            In poker, a Royal flush is the highest
                            possible hand. It consists of a straight
                            flush that ends in an Ace, and it is the
                            best possible hand in most forms of poker.
                            The odds of getting a Royal flush are around
                            1 in 649,740, which is why many casinos
                            offer a bonus to players who manage to get
                            one.
                        </p>
                    </div>
                    <div>
                        <h3 className="poker-hand-title">
                            WHAT’S THE BEST STARTING HAND FOR POKER?
                        </h3>
                        <p className="poker-hand-description">
                            The best starting hand in poker depends on
                            the game being played and the specific
                            situation. In general, strong starting hands
                            are pairs of face cards and pocket pairs,
                            and the strongest hand is usually a pair of
                            Aces. However, other factors such as
                            position and the cards on the board can also
                            affect the strength of a starting hand.
                        </p>
                    </div>
                    <div>
                        <h3 className="poker-hand-title">
                            WHAT’S THE WORST STARTING HAND IN TEXAS
                            HOLD’EM?
                        </h3>
                        <p className="poker-hand-description">
                            In Texas Hold'em, the worst starting hand is
                            often considered to be 7-2 off-suit, which
                            means a hand consisting of the 7 and 2 of
                            different suits. This hand is considered to
                            be very weak because it does not contain any
                            pairs or high cards, and it is unlikely to
                            make a straight or flush. As a result, it is
                            generally considered to be a poor starting
                            hand that should be folded in most
                            situations.
                        </p>
                    </div>
                    <div>
                        <h3 className="poker-hand-title">
                            SHOULD I FOLD MOST OF MY HANDS WHILE PLAYING
                            POKER?
                        </h3>
                        <p className="poker-hand-description">
                            In poker, folding is an important strategy,
                            but it should not be used excessively. You
                            should only fold if you have a weak hand or
                            if you face a strong bet from another
                            player. It is also important to be cautious
                            of being in too many hands, as this can be
                            costly and can lead to losing a significant
                            portion of your stack. Therefore, it is
                            important to carefully evaluate your hand
                            and the situation at the table before
                            deciding whether to fold or play on.
                        </p>
                    </div>
                    <div>
                        <h3 className="poker-hand-title">
                            ARE THE HAND RANKINGS THE SAME IN ALL FORMS
                            OF POKER?
                        </h3>
                        <p className="poker-hand-description">
                            In most forms of poker, a Royal flush is the
                            highest hand, followed by a straight flush,
                            four of a kind, and so on. However, hand
                            rankings can vary slightly depending on the
                            variant of poker being played. It is
                            important to know the specific hand rankings
                            for the game you are playing.
                        </p>
                    </div>
                </GradientBox>
            </div>
        </>
    );
};

export default PokerRules;

import React from 'react'
import PageBackground from '../../components/Layouts/PageBackground'
import './B2BPage.css'

const B2BPage = () => {
    return (
        <PageBackground title="B2B SOLUTIONS">
            <h3 className='b2b-title'>User-friendly, Customizable Poker Client & More</h3>
            <div className='b2b-uoter-box'>
                <div className='gradient-box-text-holder'>
                    <div className='b2b-scroll-box'>
                        <p>
                            Odysseus Software’s user-friendly poker client features everything licensees need to launch their own branded, uniquely customizable poker rooms, with multiple game variations, and extremely detailed settings and customization options to fit all clients needs.
                        </p>
                        <br />
                        <p>
                            Additionally, customers receive a completely integrated player management platform, in which our poker client is supported by industry leading back-end management tools.
                        </p>
                        <br />
                        <p>
                            Licensees can benefit from targeted promotions, bonus tools utilizing our web3 integrations for metamask support, fully customizable social and streaming page with integrated backend management system, and proper security systems with collision prevention and detection tools.
                        </p>
                        <br />
                        <p>
                            Our Service is available on all channels and platforms, including native mobile iOS & Android, Desktop PC, HTML5 web and HTML5 mobile and our Random Number Generator has been fully audited across all platforms.
                        </p>
                        <br />
                        <p>
                            We offer a huge range of poker games, including Cash Games, Jackpot 'Sit n Go’ games and Multi-Table Tournaments. Poker variants include Texas Hold’em, Omaha, Omaha5, and more.
                        </p>
                        <br />
                        <p>
                            Odysseus Software provides customizable solutions and can deliver a personalized offering based on the gaming patterns of players and preferences of both new and experienced players. Additionally, other games are also available to integrate within our poker client, giving players a seamless experience and licensees the ability to provide more offerings and enter more verticals with their players.
                        </p>
                        <br />
                        <p>
                            For pricing, more information, or all other inquiries please use the Contact Us page.
                        </p>
                    </div>
                </div>
            </div>
        </PageBackground>
    )
}

export default B2BPage
import React from 'react'
import PageBackground from '../../components/Layouts/PageBackground';
import './PrivacyPage.css'

const PrivacyPage = () => {
    return (
        <PageBackground title="PRIVACY POLICY">
            <div className='b2b-uoter-box'>
                <div className='gradient-box-text-holder'>
                    <div className='privacy-scroll-box'>
                        <p>
                            Privacy Policy<br />
                            Last updated: Feb 1st, 2022<br />
                            <br />
                            Odysseus Software Company (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the Social Poker (&ldquo;SoPo&rdquo;) mobile application (the &quot;Service&quot;).<br />
                            This page informs you of our policies regarding the collection, use and disclosure of Personal Information when you use our Service.<br />
                            <br />
                            We will not use or share your information with anyone except as described in this Privacy Policy.<br />
                            <br />
                            We use your Personal Information for providing and improving the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.<br />
                            <br />
                            Information Collection and Use<br />
                            While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your email address, name, phone number, postal address, other information (&quot;Personal Information&quot;).<br />
                            <br />
                            Log Data<br />
                            When you access the Service by or through a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, and other statistics (&quot;Log Data&quot;).<br />
                            <br />
                            In addition, we may use third party services such as Google Analytics that collect, monitor, and analyze this type of information in order to increase our Service's functionality. These third-party service providers have their own privacy policies addressing how they use such information.<br />
                            <br />
                            Location Information<br />
                            We may use and store information about your location, if you give us permission to do so. We use this information to provide features of our Service, to improve, and to customize our Service. You can enable or disable location services when you use our Service at anytime, through your mobile device settings.<br />
                            <br />
                            <br />
                            Information Collected<br />
                            Odysseus Software Company&rsquo;s primary objective in collecting user information is to provide and enhance the Service and to enable users to enjoy the Service. When you install, play Odysseus Software Company&rsquo;s mobile application SoPo or access any of our Services, Odysseus Software Company may automatically receive, collect, and record certain information about you, whether through the Services directly or through a third-party social network (like Facebook) or other third-party services (&quot;Account Information&quot;).<br />
                            <br />
                            Personal Information<br />
                            &ldquo;Personal information&rdquo; means information about you that can be used to contact or identify you. The personal information types collected in accordance with the Service may vary depending on the activity and may include:<br />
                            1. Your first and last name (or the name you have associated with your device);<br />
                            2. Your social network or third-party service user identification number (like your Facebook ID number), which is linked to publicly-available information like your name and profile photo (including the social network ID numbers and other public data of your friends);<br />
                            3. Your mobile device&rsquo;s unique identifier (UDID); <br />
                            4. Your telephone number;<br />
                            5. Transaction identifiers for purchases;<br />
                            6. Your email address used to login the social network;<br />
                            7. Your profile picture or its URL; or<br />
                            8. Any other relevant information included in your application, social network or other online profile.<br />
                            <br />
                            Additionally Odysseus Software Company may acquire information from you through:<br />
                            1. Your access and participation in message boards;<br />
                            2. The contents of your requests to our customer support; or<br />
                            3. Your purchase of virtual items of any type.<br />
                            <br />
                            If you purchase a license to use in-game virtual currency or virtual items in any of our games, our third-party payment processor will collect the billing and financial information it needs to process your charges. This may include your postal address, e-mail address, and financial information. Odysseus Software Company does not collect or store your financial information, like credit card numbers. However, Odysseus Software Company&rsquo;s payment processors may share non-financial information with us related to your purchases, like your name, billing address, and the items purchased in order to fulfill your order.<br />
                            <br />
                            Odysseus Software Company may also obtain the billing and payment information that you provide when your purchase is processed by another party (like Facebook, Apple, or Google) such as when you purchase something in a game you play on a social network or that you downloaded on your mobile device however does not store it.<br />
                            <br />
                            Our Terms of Service explain our policies and terms relevant to our charges, billing practices, third party credits, and virtual currencies. Please note that such purchases may also be subject to additional policies of such third-party site.<br />
                            <br />
                            Non-Personal Information<br />
                            &ldquo;Non-personal information&rdquo; is information that, when taken alone, cannot be used to identify or contact you. Odysseus Software Company may collect non-personal information about your use of its Service in the course of your game play. Odysseus Software Company may also invite you to share non-personal information about yourself which may include but is not limited to:<br />
                            1. Your age and date of birth;<br />
                            2. Your gender;<br />
                            3. Information about the hardware you may own;<br />
                            4. Your country; <br />
                            5. If applicable, your physical geolocation and that of the devices you use to access our Services; <br />
                            6. Your favorite websites, friend information, and fan pages; or<br />
                            7. Any other information included in your application, social network or other online profile.<br />
                            <br />
                            If non-personal information is collected for an activity that also requires personal information, Odysseus Software Company may combine your non-personal information with your personal information in an attempt to provide you with a better user experience, to improve the value and quality of the Services, to analyze how its Services are used, and to create new features, promotions and functionalities. The Service can also record game play information such as your game session duration or your hands&rsquo; history.<br />
                            <br />
                            In addition to information collected as described above, you may choose to share additional information about yourself throughout the Service, such as on club chat and in-game chat rooms. Any actions you perform with other players are considered public and are in your sole discretion.<br />
                            <br />
                            Other information Odysseus Software Company may collect includes your occupation, language, zip code, area code, location, and the time zone where a Service is used, as well as information about your computer, mobile device, software, platform, incident data, Internet Protocol (IP) address, network Media Access Control (MAC) address and connection, browser type, browser language, referring pages, exit pages and URLs, number of clicks, domain names, landing pages, pages viewed and the order of those pages, statistics about your interactions with the Service, the amount of time spent on particular pages, and the date and time of this activity, so that Odysseus Software Company can better understand customer behavior and improve its products, Services, and advertising. Odysseus Software Company may also receive either non-personal or public information from third parties in connection with market and demographic studies and/or data that Odysseus Software Company may use to supplement personal information provided directly by you.<br />
                            <br />
                            Use of Your Information<br />
                            <br />
                            Cookies<br />
                            Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your computer's hard drive.<br />
                            <br />
                            We use &quot;cookies&quot; to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.<br />
                            <br />
                            Behavioral Remarketing<br />
                            Odysseus Software Company may use remarketing/retargeting services provided by Facebook, Google, Snapchat, Twitter, etc., to advertise on third party platforms to you on basis of information collected upon your access to our Service. We, and our third-party vendors, use cookies and/or advertising identifiers to inform, optimize and serve ads based on your past access to our Service. Depending on the privacy settings on your device, the information collection may initiate immediately when you open our application.<br />
                            <br />
                            Our use of remarking/retargeting services will always be in compliance with the privacy settings on your device, and the applicable platform rules. You may opt out of targeted ads by disabling advertising and tracking cookies in your browser, or by refusing tracking in your device&rsquo;s privacy settings. You may consult official support information pages (usually entitled &ldquo;Privacy&rdquo; or &ldquo;Advertising&rdquo;) to learn how to refuse tracking on a particular browser/device. The following links may also contain useful information. <br />
                            &bull; For iOS devices: https://support.apple.com/en-us/HT205223.<br />
                            &bull; For Android devices: https://support.google.com/googleplay/android developer/answer/6048248?hl=en#zippy=%2Chow-to-opt-out-of-personalized-ads.<br />
                            &bull; For Google: https://policies.google.com/privacy?hl=en.<br />
                            &bull; For Facebook: https://www.facebook.com/help/568137493302217.<br />
                            &bull; For Twitter: https://help.twitter.com/en/safety-and-security/privacy-controls-for tailored-ads.<br />
                            <br />
                            <br />
                            How Odysseus Software Company Uses Your Information<br />
                            Odysseus Software Company uses your information to create your SoPo accounts and allows you to play our game, to operate, and optimize our Services, to provide you with a safe, smooth, efficient, and customized experience, to fulfill your specific requests and purchase orders, and to send you other account-related information. In addition, the personal information you provide will allow Odysseus Software Company to send you messages regarding, among other things, in-games (in-app) updates, new products, features, enhancements, special and promotional offers, upgrade opportunities, contests and events of interest. We will keep you informed of your in-game (in-app) activity, including comments from friends, notifications about in-game (in-app) status as well as notifications of gifts. You may also later opt out of certain of these communications, as further detailed below.<br />
                            <br />
                            Odysseus Software Company may also use personal and non-personal information, both individually and combined together, to better understand the behavior and preferences of its customers, to identify, suggest and enable communication and connections with other Odysseus Software Company users and personalize our Services to you, to provide technical support and respond to inquiries, to troubleshoot technical problems, to help create a safer and more trusted environment for our players by preventing fraud or potentially illegal activities, to deliver and enforcing our Terms of Service, target and serve static and dynamic advertising, to enforce our Terms of Service, to provide an in-game (in-app) leaderboard and promote in-game (in-app) player game records and hand history, to solicit input and feedback to improve Odysseus Software Company products and Services and their content, to ensure proper functioning of our products and Services, to manage and deliver contextual and behavioral advertising, to administer rewards, surveys, contests, or other promotional activities or events sponsored or managed by us or our business partners, to comply with our legal obligations, resolve any disputes we may have with you or other players, and to enforce our agreements with third parties and conduct research.<br />
                            <br />
                            In all cases of data access and collection, the information you provide will not be disclosed, rented, loaned, leased, sold, or otherwise voluntarily distributed to unaffiliated third parties and will be used solely for the purpose stated herein. Odysseus Software Company will retain your information for as long as your account is active or as needed to provide you Services depends on why we collected this information and how we use it. We will not retain your personal information for longer than is necessary for our business purposes or for legal requirements. For instance, we may retain some information for a few years after you have closed your account if this is necessary to meet our legal obligations or to exercise, defend or establish legal rights. If you wish to cancel your account or request that Odysseus Software Company no longer use your information contact Odysseus Software Company at the email address provided in the Your Rights section below. Note that there may be instances where Odysseus Software Company is legally required to retain your information.<br />
                            <br />
                            <br />
                            <br />
                            Communications<br />
                            Where you have consented, we may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.<br />
                            <br />
                            Compliance With Laws<br />
                            We will disclose your Personal Information where required to do so by law or subpoena or if we believe that such action is necessary to comply with the law and the reasonable requests of law enforcement or to protect the security or integrity of our Service.<br />
                            <br />
                            Business Transaction<br />
                            If Odysseus Software Company is involved in a merger, acquisition or asset sale, your Personal Information may be transferred. We will provide notice before your Personal Information is transferred and becomes subject to a different Privacy Policy.<br />
                            <br />
                            Security<br />
                            The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.<br />
                            <br />
                            <br />
                            Links To Other Sites<br />
                            Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.<br />
                            We have no control over, and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.<br />
                            <br />
                            Children's Privacy<br />
                            Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with Personal Information, please contact us. If we become aware that we have collected Personal Information from a child under age 13 without verification of parental consent, we take steps to remove that information from our servers.<br />
                            <br />
                            Changes To This Privacy Policy<br />
                            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.<br />
                            <br />
                            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.<br />
                            <br />
                            Your Rights<br />
                            Our postal address is 1011 w. Cleveland St. Tampa, FL 33609 United States, and our email contact is support@playsopo.com. <br />
                            <br />
                            You have the right to access personal information that we process about you.<br />
                            You can request information about:<br />
                            &bull; What personal data we hold about you;<br />
                            &bull; The purposes of the processing;<br />
                            &bull; The categories of personal data concerned;<br />
                            &bull; The recipients to whom the personal data has/will be disclosed;<br />
                            &bull; How long we intend to store your personal data for;<br />
                            &bull; If we did not collect the data directly from you, information about the source.<br />
                            <br />
                            You may also request that we update any incomplete or inaccurate data about you. We will strive to correct this as quickly as possible unless there are valid reasons for not doing so.<br />
                            <br />
                            You also have the right to request that we delete your personal data in accordance with GDPR.<br />
                            <br />
                            You may request we action your rights by contacting us at Odysseus Software Company. You can make your request by emailing us at support@playsopo.com. <br />
                            If we receive a request from you to exercise any of the above rights, we will ask you to verify your identity before acting on the request; this is to ensure that your data is protected and kept secure.<br />
                        </p>
                    </div>
                </div>
            </div>
        </PageBackground>
    )
}

export default PrivacyPage
import React from 'react';
import PageBackground from '../../components/Layouts/PageBackground';
import { socialfeatures } from '../../data';
import './Features.css';

const Features = () => {
  return (      
      <PageBackground
        title="FEATURES"
      >
        <div className="social-container">
        {socialfeatures.map((features, i) => 
          <>
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front" style={{background: `black url(${features.image})`}}>
                  <div class="flip-card-content">
                    {features.title}
                  </div>
                </div>
                <div class="flip-card-back" style={{background: `black url(${features.image})`}}>
                  <div class="flip-card-content">
                    {features.description}
                  </div>
                </div>
              </div>
            </div>
            </>
          )}
        </div>
      </PageBackground>
  );
};

export default Features;

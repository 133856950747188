import how1 from './assets/images/howtoplay/howtoplay_1.png';
import how2 from './assets/images/howtoplay/howtoplay_2.png';
import how3 from './assets/images/howtoplay/howtoplay_3.png';
import how4 from './assets/images/howtoplay/howtoplay_4.png';

import features1 from './assets/images/socialfeatures//features_1.png';
import features2 from './assets/images/socialfeatures/features_2.png';
import features3 from './assets/images/socialfeatures/features_3.png';
import features4 from './assets/images/socialfeatures/features_4.png';
import features5 from './assets/images/socialfeatures/features_5.png';
import features6 from './assets/images/socialfeatures/features_6.png';
import features7 from './assets/images/socialfeatures/features_7.png';
import features8 from './assets/images/socialfeatures/features_8.png';
import features9 from './assets/images/socialfeatures/features_9.png';

import titleimage from './assets/images/howtoplay/circle.svg';
import learnMore from './assets/images/howtoplay/learn-more.svg';

import certified1 from './assets/images/certifications/certified_1.png';
import certified2 from './assets/images/certifications/certified_2.png';
import certified3 from './assets/images/certifications/certified_3.png';
import certified4 from './assets/images/certifications/certified_4.png';

import highcard from './assets/images/pokerrules/poker-hand-ranking/high_card.svg';
import onepair from './assets/images/pokerrules/poker-hand-ranking/one_pair.svg';
import twopair from './assets/images/pokerrules/poker-hand-ranking/two_pair.svg';
import threekind from './assets/images/pokerrules/poker-hand-ranking/three_of_kind.svg';
import straight from './assets/images/pokerrules/poker-hand-ranking/straight.svg';
import flush from './assets/images/pokerrules/poker-hand-ranking/flush.svg';
import fullhouse from './assets/images/pokerrules/poker-hand-ranking/full_house.svg';
import fourkind from './assets/images/pokerrules/poker-hand-ranking/four_of_kind.svg';
import straightflush from './assets/images/pokerrules/poker-hand-ranking/straight_flush.svg';
import royalflush from './assets/images/pokerrules/poker-hand-ranking/royal_flush.svg';

import acejack from './assets/images/pokerrules/starting-hands-texas/acejack.svg';
import aceking from './assets/images/pokerrules/starting-hands-texas/aceking.svg';
import acequeen from './assets/images/pokerrules/starting-hands-texas/acequeen.svg';
import aces from './assets/images/pokerrules/starting-hands-texas/aces.svg';
import jacks from './assets/images/pokerrules/starting-hands-texas/jacks.svg';
import kingjack from './assets/images/pokerrules/starting-hands-texas/kingjack.svg';
import kings from './assets/images/pokerrules/starting-hands-texas/kings.svg';
import nines from './assets/images/pokerrules/starting-hands-texas/nines.svg';
import queens from './assets/images/pokerrules/starting-hands-texas/queens.svg';
import tens from './assets/images/pokerrules/starting-hands-texas/tens.svg';

import cards1 from './assets/images/pokerrules/starting-hands-omaha/cards1.svg';
import cards2 from './assets/images/pokerrules/starting-hands-omaha/cards2.svg';
import cards3 from './assets/images/pokerrules/starting-hands-omaha/cards3.svg';
import cards4 from './assets/images/pokerrules/starting-hands-omaha/cards4.svg';
import cards5 from './assets/images/pokerrules/starting-hands-omaha/cards5.svg';
import cards6 from './assets/images/pokerrules/starting-hands-omaha/cards6.svg';
import cards7 from './assets/images/pokerrules/starting-hands-omaha/cards7.svg';
import cards8 from './assets/images/pokerrules/starting-hands-omaha/cards8.svg';
import cards9 from './assets/images/pokerrules/starting-hands-omaha/cards9.svg';
import cards10 from './assets/images/pokerrules/starting-hands-omaha/cards10.svg';

export const howtoplay = [
    {
        image: how1,
        title: 'QUICKPLAY',
        description: 'Instantly create a table & share an invite link with who you want to play with. Multiple game modes, full tournament customization, and no account required.',
    },
    {
        image: how2,
        title: 'CLUBS',
        titleimage: titleimage,
        secondTitleImage: learnMore,
        description: 'Create a club and run tournaments with your friends completely for free, absolutely no diamonds. Generate reports, customize your club, and chat with other club members.',
    },
    {
        image: how3,
        title: 'FREEPLAY',
        description: 'Play and earn gold to spend in the shop on avatars, time banks, emotes, and more. Play both game modes, NLH or Jackpot, and test your skills!',
    },
    {
        image: how4,
        title: 'SWEEPSTAKES',
        description: 'For the first time ever, play sweepstakes poker on mobile. Redeem sweepsbucks to USD & transfer direct to your bank! Get Sweepsbucks in Gold packages in the shop.',
    },
];
export const pokerhand = [
    {
        title: 'HIGH CARD',
        description:
            'When a poker hand does not meet the requirements for any other hand combinations, the strength of the hand is determined by the highest-ranked card. In the event of a tie with another player, the next most significant card, called the kicker, is used to determine the winner. The suits of the cards do not matter in this case.',
        probality: '1 in 0.99',
        combinations: '1,302,540',
        image: highcard,
    },
    {
        title: 'ONE PAIR',
        description:
            'Having two cards of the same denomination. In the event of a tie, the kicker is used to determine the winner. The highest-ranked pair wins. As with the previous example, the suits of the cards do not matter.',
        probality: '1 in 1.36',
        combinations: '1,098,240',
        image: onepair,
    },
    {
        title: 'TWO CARD',
        description:
            'Having two pair in poker means having two sets of pairs in your hand. If two players have the same pairs, the highest kicker wins. As always, the suits of the cards do not matter.',
        probality: '1 in 20',
        combinations: '123,552',
        image: twopair,
    },
    {
        title: 'THREE OF A KIND (A.K.A. TRIPS)',
        description:
            'Having three of a kind in poker means having three cards of the same denomination in your hand. In the event of a tie with another player, kickers are used to determine the winner. As with the previous examples, the suits of the cards do not matter.',
        probality: '1 in 46',
        combinations: '54,912',
        image: threekind,
    },
    {
        title: 'STRAIGHT',
        description:
            'Having a straight in poker means having five cards in sequential order in your hand. The suits of the cards do not matter. The Ace can be used as either the high or low card in a straight, and is the only card that can be used in this way.',
        probality: '1 in 253',
        combinations: '10,200',
        image: straight,
    },
    {
        title: 'FLUSH',
        description:
            'Having a flush in poker means having five cards of the same suit in any order in your hand. The numbers on the cards do not matter. There are four suits in a deck of cards: diamonds, hearts, spades, and clubs.',
        probality: '1 in 508',
        combinations: '5,108',
        image: flush,
    },
    {
        title: 'FULL HOUSE',
        description:
            'Having a full house in poker means having three cards of the same denomination and two of another denomination in your hand. This is a combination of three of a kind and a pair. The suits of the cards do not matter.',
        probality: '1 in 693',
        combinations: '3,744',
        image: fullhouse,
    },
    {
        title: 'FOUR OF A KIND (A.K.A. QUADS)',
        description:
            'Having four of a kind in poker means having four cards of the same denomination in your hand. The suits of the cards do not matter.',
        probality: '1 in 4,164',
        combinations: '624',
        image: fourkind,
    },
    {
        title: 'STRAIGHT FLUSH',
        description:
            'Having a straight flush in poker means having five cards in sequential order that are also of the same suit in your hand. This is a combination of a straight and a flush. The suits of the cards do not matter.',
        probality: '1 in 72,193',
        combinations: '36',
        image: straightflush,
    },
    {
        title: 'ROYAL FLUSH',
        description:
            'Having a royal flush in poker means having a hand that consists of a 10, Jack, Queen, King, and Ace of the same suit. This is the only unbeatable hand in poker and is a combination of a straight and a flush, with the added requirement of being a high straight. The suits of the cards do not matter as long as they’re all uniform.',
        probality: '1 in 649,740',
        combinations: '4',
        image: royalflush,
    },
];
export const startinghandstexas = [
    {
        title: 'POCKET ACES',
        description:
            'The starting hand of two aces, also known as pocket aces, is the best hand in the game of Texas Hold’em. Before the flop, pocket aces cannot be beaten by any other hand. In general, pocket aces have a win rate of about 80 percent, making them a strong favorite over most other hands. On average, a player should be dealt pocket aces once every 221 hands.',
        image: aces,
    },
    {
        title: 'TWO KINGS',
        description:
            'Pocket kings, also known as a pair of kings, are the second best starting hand in Texas Hold’em. Before the flop, they are the favorite against any other hand, except for pocket aces. In general, pocket kings have a win rate of about 75 percent, making them a strong favorite over most other hands.',
        image: kings,
    },
    {
        title: 'TWO QUEENS',
        description:
            'Pocket queens, also known as a pair of queens, is a strong starting hand in Texas Hold’em. Before the flop, it will beat the majority of other hands. However, the chances of winning with pocket queens start to decrease on the flop, turn, and river.',
        image: queens,
    },
    {
        title: 'TWO JACKS',
        description:
            'Pocket jacks, also known as a pair of jacks, is the fourth best starting hand in Texas Hold’em. While it is not a top pair, it is still a strong hand that can win you the game. In general, pocket jacks win about 20 percent of the time. It’s important to keep an eye out for larger pairs, straights, and flushes when playing with pocket jacks.',
        image: jacks,
    },
    {
        title: 'ACE, KING',
        description:
            'Ace-king, also known as big slick, is a strong drawing hand in Texas Hold’em with a decent win percentage. Ace-king suited, where both cards are the same suit, is the best possible version of this hand because it increases the chances of making a flush.',
        image: aceking,
    },
    {
        title: 'TWO TENS',
        description:
            'Pocket tens, also known as a pair of tens, is a solid starting hand in Texas Hold’em. However, it can be difficult to play if there are face cards on the flop. Pocket tens are strong enough to win on their own and can be a great hand to play on the turn and river if the betting is low.',
        image: tens,
    },
    {
        title: 'ACE, QUEEN',
        description:
            'Ace-queen suited, where both cards are the same suit, is a strong drawing hand, with a win percentage of about 20 percent. It is a good starting hand that can win you the game if played well.',
        image: acequeen,
    },
    {
        title: 'ACE, JACK',
        description:
            'Ace-jack, also known as blackjack, is a good drawing hand in Texas Hold’em, especially if it is suited. This hand has excellent potential to make a straight. However, it’s important to keep in mind that any ace-king or ace-queen combination will beat it if an ace comes out on the board.',
        image: acejack,
    },
    {
        title: 'KING, JACK',
        description:
            'King-jack, also known as KJ, is a good starting hand in Texas Hold’em, especially in later positions. If it is suited, it will win about 19 percent of the time. If it is unsuited, its win percentage drops to 15 percent.',
        image: kingjack,
    },
    {
        title: 'TWO NINES',
        description:
            'Pocket nines, also known as a pair of nines, is a reliable starting hand in Texas Hold’em. It is best to play this hand later in the game if the betting is low and you are the last to act. It is a good hand to have in your arsenal.',
        image: nines,
    },
];
export const startinghandsomaha = [
    {
        title: 'ACE ACE KING KING',
        rank: '1',
        image: cards1,
    },
    {
        title: 'ACE ACE JACK TEN',
        rank: '2',
        image: cards2,
    },
    {
        title: 'ACE ACE QUEEN QUEEN',
        rank: '3',
        image: cards3,
    },
    {
        title: 'ACE ACE JACK JACK',
        rank: '4',
        image: cards4,
    },
    {
        title: 'ACE ACE TEN TEN',
        rank: '5',
        image: cards5,
    },
    {
        title: 'ACE ACE 9 9',
        rank: '6',
        image: cards6,
    },
    {
        title: 'ACE ACE XX XX',
        rank: '7',
        image: cards7,
    },
    {
        title: 'JACK TEN 9 8',
        rank: '8',
        image: cards8,
    },
    {
        title: 'KING KING QUEEN QUEEN',
        rank: '9',
        image: cards9,
    },
    {
        title: 'KING KING JACK JACK',
        rank: '10',
        image: cards10,
    },
];
export const certifiedfair = [
    {
        image: certified1,
        title: "GLI",
        description:
            'Provides the gaming industry’s leading testing and certification services.',
    },
    {
        image: certified2,
        openpdf: true,
        title: "CFG",
        description:
            'The industry leader in auditing game fairness for Internet casinos.',
    },
    {
        image: certified3,
        title: "DWOLLA",
        description:
            'A payment service provider that offers a modern API for businesses to seamlessly connect to the U.S. payment networks.',
    },
    {
        image: certified4,
        title: "PLAID",
        description:
            'A payment service that makes it easy for users to connect their financial accounts securely and quickly to SOPO.',
    },
];

export const socialfeatures = [
    {
        image: features1,
        title: 'MULTITABLING',
        description: 'Play up to 4 tables at once all in the same view!',
    },
    {
        image: features2,
        title: 'PLACE POKER PROP PICKS',
        description: 'Place "picks" on popular live streams and wager for real prizes!',
    },
    {
        image: features3,
        title: 'WATCH STREAMS',
        description: 'Watch live streams in the same window as your active tables!',
    },
    {
        image: features4,
        title: 'GLOBAL FREEPLAY',
        description: 'Play freeplay gamemodes internationally at multiple levels of stakes!',
    },
    {
        image: features5,
        title: 'MOBILE POKER SWEEPSTAKES',
        description: 'For the first time ever, play poker sweepstakes on mobile devices!',
    },
    {
        image: features6,
        title: 'STAKING',
        description: 'Stake your favorite poker players all within the app!',
    },
    {
        image: features7,
        title: 'NEWS',
        description: 'Stay up to date with current news written by our in house media team!',
    },
    {
        image: features8,
        title: 'CUSTOMIZE',
        description: 'Customize your background, felt, avatar, bet size presets, and more!',
    },
    {
        image: features9,
        title: 'CREATE/JOIN CLUBS WITH FRIENDS',
        description: 'SOPO is a no purchase necessary experience with a full reporting system!',
    },
];

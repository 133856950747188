import React, { useState } from 'react';
import './PageBackground.css';
import SOPOLogo from '../../assets/icons/sopoLogo.png';
import Divider from '../../assets/icons/divider.png';
import HomeIcon from '../../assets/images/howtoplay/icons/home-icon.png'
import Title from '../Title';
import PokerRules from '../../../src/views/PokerRules/PokerRules';

const PageBackground = ({ title, children, overlay, howtoplay }) => {
    const [openPokerRules, setOpenPokerRules] = useState(false);

    return (
        <>
        {openPokerRules && <div className="pokerRulesOverlay"></div>}
        {overlay && <div className="overlay"></div>}
        <div className="background">
        <div className="page-container" style={{ position: 'relative' }}>
                <div className="top-container">
                    <div class="left-container">
                        {howtoplay && 
                            (openPokerRules ? (
                                <div class="home-button" onClick={()=>setOpenPokerRules(false)}>
                                    <img src={HomeIcon} alt="home-icon"/>
                                </div>
                                ) : (<div class="button" onClick={()=>setOpenPokerRules(true)}>LEARN THE BASICS</div>)
                            )
                        }
                        <Title title={title}></Title>
                    </div>
                    <div className="right-container">
                        <img src={SOPOLogo} alt="sopo-logo"/>
                        <img src={Divider} className="divider" alt="divider"/>
                    </div>
                </div>
                {!openPokerRules ? (<div className='background-inner-container'>{children}</div>) : (<PokerRules/>)}              
            </div>
        </div>
        </>
    );
};

export default PageBackground;

import './FourColumn.css';

const FourColumn = ({
  content,
  certifClick
}) => {
  return (
    <>
      <div className="four-column">
        <div className="column-content">
            <img
              src={content.image}
              onClick={content.openpdf && certifClick}
              className='card'
              alt="certified"
              style={{cursor: content.openpdf && certifClick ? "pointer" : ""}}
            />
            <div className="column-bottom">
              <div className="column-title">{content.title}</div>
              <div className="column-description">{content.description}</div>
            </div>
        </div>
      </div>
    </>
  );
};

export default FourColumn;

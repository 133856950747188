import React, {useState, useEffect} from 'react';
import './HomePage.css';
import Column from '../../components/Layouts/Column';
import SopoLogo from '../../assets/icons/sopoLogo.png';
import Divider from '../../assets/icons/divider.png';
import AppStore from '../../assets/icons/app-store.png';
import GooglePlay from '../../assets/icons/google-play.png';
import BannerImage from '../../assets/images/home/bannerImage.png';
import MobileBannerImage from '../../assets/images/home/mobileBannerImage.png';

const HomePage = () => {
  const [mobile, setmobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 885) {
      setmobile(true);
    }
  }, []);
  return (
    <div className="homepage-container">
      <div className="row homepage-row">
        { !mobile ? (
          <>
            <div className='home-column left'>
              <div>
                <img src={SopoLogo} alt="sopoLogo"/>
              </div>
              <div>
                <img src={Divider} alt="divider"/>
              </div>
              <div className="home-description">
                <p>No Purchase Necessary | Certified Fair | Industry Leading</p>
                <p className="bold-text">READY FOR THE FUTURE</p>
              </div>
              <div className="play-now-btn play-now-btn-home" onClick={()=>window.open("https://app.playsopo.com/link/open")}>PLAY NOW IN BROWSER</div>
              <div className="store-icons">
                <img onClick={()=>window.open("https://apps.apple.com/us/app/sopo-social-poker/id1441432679")} src={AppStore} alt="store-icons"/>
                <img onClick={()=>window.open("https://play.google.com/store/apps/details?id=software.odysseus.sopo&hl=en&gl=US")} src={GooglePlay} alt="google-play-icons"/>
              </div>
            </div>
            <div div className='home-column right'>
              <div>
                <img src={BannerImage} alt="bannerImage"/>
              </div>
            </div>
          </> ) : (
            <>
              <Column>
                <div>
                  <img src={SopoLogo} alt="sopoLogo"/>
                </div>
                <div>
                  <img src={Divider} alt="divider"/>
                </div>
                <div className="home-description">
                  <p>No Purchase Necessary | Certified Fair | Industry Leading</p>
                  <p className="bold-text">READY FOR THE FUTURE</p>
                </div>
                {/* <div className="play-now-btn play-now-btn-home" onClick={()=>window.open("https://app.playsopo.com/link/open")}>PLAY NOW IN BROWSER</div> */}
              </Column>
              <Column>
                <div>
                  <img src={MobileBannerImage} alt="bannerImage"/>
                </div>
              </Column>
              <Column>
                <div className="store-icons">
                  <img onClick={()=>window.open("https://apps.apple.com/us/app/sopo-social-poker/id1441432679")} src={AppStore} alt="store-icons"/>
                  <img onClick={()=>window.open("https://play.google.com/store/apps/details?id=software.odysseus.sopo&hl=en&gl=US")} src={GooglePlay} alt="google-play-icons"/>
                </div>
              </Column>
          </>
          )}
      </div>
    </div>
  );
};

export default HomePage;

import React from 'react';
import PageBackground from '../../components/Layouts/PageBackground';
import './Web3Integration.css';

const Web3Integration = () => {
  return (
    <PageBackground
      title="web3"
      overlay={true}
    >
      <div className="padding-container">
        <div className="web3-container">
          <div className="first-line"></div>
          <p>SOPO is excited for the future of gaming technology involving web3. That's why we are launching with a full suite of features that embrace the best aspects of NFTs.</p>
          <p>Safely & easily connect your Metamask account, and our in-house aggregator will import your NFTs to your profile so you can use them as an avatar! Have a unique 1:1 piece? Other players can click on your icon at the table and view metadata while never leaving the table!</p>
          <p>We have more features coming this year and look forward to building out more for our users.</p>
          <div className="second-line">
            <div className="yellow-line"></div>
            <div className="white-line"></div>
          </div>
        </div>
      </div>
    </PageBackground>
  );
}

export default Web3Integration;

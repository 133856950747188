import React from 'react';
import '../PokerRules.css';

const StartingHandsTexas = ({ title, description, cards }) => {
    return (
        <>
            <div className="starting-hands">
                <div>
                    <h3 className="poker-hand-title">{title}</h3>
                    <p className="poker-hand-description">{description}</p>
                </div>
                <div className="image-container">
                    <img className="starting-hand-cards" src={cards} />
                </div>
            </div>
        </>
    );
};

export default StartingHandsTexas;

import React from 'react';
import '../PokerRules.css';

const PokerHand = ({ title, description, probality, combinations, cards }) => {
    return (
        <>
            <div className="poker-hand">
                <h3 className="poker-hand-title">{title}</h3>
                <p className="poker-hand-description">{description}</p>
                <p className="poker-hand-footerText">
                    Probability: <span>{probality}</span> | Combinations:{' '}
                    <span>{combinations}</span>
                </p>
                <img className="cards" src={cards} />
            </div>
        </>
    );
};

export default PokerHand;

import React from 'react';
import './Card.css';
import DesktopIcon from '../../../assets/images/howtoplay/icons/desktop.png';
import SmartphoneIcon from '../../../assets/images/howtoplay/icons/smartphone.png';
import InfoIcon from '../../../assets/images/howtoplay/icons/info-icon.png';

const Card = ({data, infoClick}) => {
    return (
        <div className="card-container">
            <div className="card-image"><img src={data.image} alt="card1"/></div>
            <div className="card-content">
                <div className="card-header">
                    <h1 className="card-title">{data.title}</h1>
                    {data.title === "CLUBS" && <div className="info-icon" onClick={infoClick}>
                        <img className="card-info-icon" src={InfoIcon} alt="info-icon"/>
                    </div> }
                </div>
                <p className="card-description">{data.description}</p>
                <div className="device-icons">
                    {data.title !== "SWEEPSTAKES" && data.title !== "FREEPLAY" && <img className="card-device-icon desktop" src={DesktopIcon} alt="device-icon"/>}
                    {data.title !== "QUICKPLAY" &&<img className="card-device-icon smartphone" src={SmartphoneIcon} alt="device-icon"/>}
                </div>
            </div>
        </div>
    );
};

export default Card;

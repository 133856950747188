import React from 'react';
import PageBackground from '../../components/Layouts/PageBackground';
import Card from './components/Card';
import Carousel from '../../components/Carousel';
import Modal from 'react-modal';
import Close from '../../assets/images/howtoplay/carousel/close.png';
import { howtoplay } from '../../data';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '0px!important',
        transform: 'translate(-50%, -50%)',
    },
};

const HowtoPlay = () => {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    return (
        <div>
            {
                <PageBackground
                    title="HOW TO PLAY"
                    howtoplay
                >

                    { howtoplay.map((object, i) => <Card data={object} key={i} infoClick={openModal}/>) }
                    <div>
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                        >
                            <Carousel />
                            <button
                                className="closeButton"
                                onClick={closeModal}
                            >
                                <img src={Close} alt="close-button"/>
                            </button>
                        </Modal>
                    </div>
                </PageBackground>
            }
        </div>
    );
};

export default HowtoPlay;
